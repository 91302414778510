import React, {useEffect} from "react";
import {useDispatch} from "react-redux";

import ChangePassword from "../../components/Page/NavBar/updatePassword";
import * as actionTypes from "../../store/actions/actionTypes";
import {addAdmission} from "../../store/actions/admission";

const UpdatePasswordView= () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({type: actionTypes.LOADING_DONE});
    }, []);

    return <ChangePassword/>;
}

export default UpdatePasswordView;