import React from "react";
import {Routes, Route, Navigate} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {useSelector} from "react-redux";

import AdmissionsListView from "./views/AdmissionsListView";
import UpdatePasswordView from './views/UpdatePasswordView';
import PrivateRoute from "../components/PrivateRoute";
import AdmissionView from "./views/AdmissionView";
import NavBar from "../components/Page/NavBar/NavBar";
import ModalUI from "../components/Modal/ModalUI";
import AdmissionImpersonateView from "./views/AdmissionImpersonateView";
import ProdaWebserviceRequestView from "./views/ProdaWebserviceRequestView";
import Reloading from "../components/Reloading";
import AddAdmissionView from "./views/AddAdmissionView";
import HospitalPatientView from "./views/HospitalPatientView";
import HospitalPatientEditView from "./views/HospitalPatientEditView";
import RfaAddView from "./views/RfaAddView";
import RfaEditView from "./views/RfaEditView";
import AddHospitalPatientView from "./views/AddHospitalPatientView";
import DevApiView from "./views/DevApiView";
import DischargeListView from "./views/DischargeListView";
import Error404 from "./views/Error404";
import DevAca586View from "./views/DevAca586View";
import ManagerBanner from "../components/FlashMessages/ManagerBanner";

const AppRoutes = () => {
    const {selectedHospital, role} = useSelector(state => state.auth);
    return (
        <>
            <NavBar />
            <Reloading />
            <ManagerBanner />
            <Routes>
                {/*// TODO: Refactor duplicate routes*/}
                {/* ProdaWebserviceRequestView is added for https://matthew-maks.atlassian.net/browse/ACA-117 NOI verification. Because NOI tests are not linked to admissions */}
                {selectedHospital.manager_dischargeList_enable && role.canAccessDischargeList && <>
                    <Route path="/discharge/:hospitalId/g/:groupType/:groupId/:sortColumn/:sortMode/:page/:filter" element={<PrivateRoute component={DischargeListView} />} />
                    <Route path="/discharge/:hospitalId/g/:groupType/:groupId" element={<PrivateRoute component={DischargeListView} />} />
                    <Route path="/discharge/:hospitalId/:date/:sortColumn/:sortMode/search/:keyword" element={<PrivateRoute component={DischargeListView} />} search={true}/>
                    <Route path="/discharge/:hospitalId/:date/:sortColumn/:sortMode" element={<PrivateRoute component={DischargeListView} />} />
                    <Route path="/discharge/:hospitalId/:date" element={<PrivateRoute component={DischargeListView} />} />
                    <Route path="/discharge/" element={<PrivateRoute component={DischargeListView} />} />
                </>}

                <Route path="/dev/api" element={<PrivateRoute component={DevApiView} />} />
                <Route path="/hp/:id/rfa/:rfaId/edit" element={<PrivateRoute component={RfaEditView} />} />
                <Route path="/hp/:id/rfa/add" element={<PrivateRoute component={RfaAddView} />} />
                <Route path="/hp/:id/edit" element={<PrivateRoute component={HospitalPatientEditView} />} />
                <Route path="/hp/add" element={<PrivateRoute component={AddHospitalPatientView} />} />
                <Route path="/hp/:id" element={<PrivateRoute component={HospitalPatientView} />} />
                <Route path="/proda/:id" element={<PrivateRoute component={ProdaWebserviceRequestView} />} />
                <Route path="/admission/add" element={<PrivateRoute component={AddAdmissionView} />} />
                <Route path="/admission/:id/impersonate" element={<PrivateRoute component={AdmissionImpersonateView} />} />
                <Route path="/admission/:id" element={<PrivateRoute component={AdmissionView} />} />
                <Route path="/admissions/:hospitalId/g/:groupType/:groupId/:sortColumn/:sortMode/:page/:filter" element={<PrivateRoute component={AdmissionsListView} />} />
                <Route path="/admissions/:hospitalId/g/:groupType/:groupId" element={<PrivateRoute component={AdmissionsListView} />} />
                <Route path="/admissions/:hospitalId/:date/:sortColumn/:sortMode/search/:keyword" element={<PrivateRoute component={AdmissionsListView} />} search={true}/>
                <Route path="/admissions/:hospitalId/:date/:sortColumn/:sortMode" element={<PrivateRoute component={AdmissionsListView} />} />
                <Route path="/admissions/:hospitalId/:date" element={<PrivateRoute component={AdmissionsListView} />} />
                <Route path="/admissions/" element={<PrivateRoute component={AdmissionsListView} />} />
                <Route path="/dev/aca-586" element={<PrivateRoute component={DevAca586View} />} />
                <Route path="/update-password" element={<PrivateRoute component={UpdatePasswordView} />} />
                <Route path="/" element={<PrivateRoute component={AdmissionsListView} />} />

                <Route exact path="/demo" element={<PrivateRoute component={<Navigate to={process.env.REACT_APP_DEMO_REDIRECT} />} />} />
                <Route path="*" element={<PrivateRoute component={Error404} />} />

                {/*
                <PrivateRoute path="/proda/:id"  component={ProdaWebserviceRequestView} />
                <PrivateRoute path="/admission/:id/impersonate"  component={AdmissionImpersonateView} />
                <PrivateRoute path="/admission/:id"  component={AdmissionView} />
                <PrivateRoute path="/admissions/:hospitalId/g/:groupType/:groupId/:sortColumn/:sortMode/:page/:filter"  component={AdmissionsListView} />
                <PrivateRoute path="/admissions/:hospitalId/g/:groupType/:groupId"  component={AdmissionsListView} />
                <PrivateRoute path="/admissions/:hospitalId/:date/:sortColumn/:sortMode/search/:keyword"  component={AdmissionsListView} search={true}/>
                <PrivateRoute path="/admissions/:hospitalId/:date/:sortColumn/:sortMode"  component={AdmissionsListView} />
                <PrivateRoute path="/admissions/:hospitalId/:date"  component={AdmissionsListView} />
                <PrivateRoute path="/admissions/"  component={AdmissionsListView} />
                {/*<PrivateRoute path="/dash"  component={DashboardView} />*/}
                {/*<PrivateRoute path="/test"  component={TestView} />*/}
                {/*<PrivateRoute exact path="/demo">
                    <Navigate to={process.env.REACT_APP_DEMO_REDIRECT} />
                </PrivateRoute>*/}
                {/*<PrivateRoute path="/reports/"  component={ReportView} />*/}
                {/*<PrivateRoute path="/settings/"  component={SettingsView} />*/}
            </Routes>
            <ModalUI />
            <ReactTooltip place="top" type="dark" effect="solid"/>
       </>
    )
};

export default AppRoutes;