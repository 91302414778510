import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import dotProp from 'dot-prop-immutable';
import {format, parse} from 'date-fns';

import * as actionTypes from "../../store/actions/actionTypes";
import {parseStringTemplate} from "../../utils/stringTemplate";

const ModalAdmissionTime_ExtraField = ({item}) => {
    // console.log('[ModalAdmissionTime_ExtraField] render', item.variable);
    const {admission, extraFields, selectedMessageTemplate} = useSelector(state => state.modal.admissionTime);
    const dispatch = useDispatch();

    const getValue = () => {
        if (item.sourceDate) {
            return format(parse(dotProp.get(admission, item.defaultValue, ''), item.sourceDate, new Date()), item.targetDate);
        }
        if (item.imp161) {
            // console.log('[imp161]', extraFields);
            if (!extraFields.pricePerDay || !extraFields.lengthOfStay) return '';
            return parseFloat(extraFields.pricePerDay) * parseFloat(extraFields.lengthOfStay);
        }
        if (item.defaultValueTemplate) {
            return parseStringTemplate(item.defaultValueTemplate, {...admission, ...extraFields});
        }

        if (extraFields[item.variable]) {
            return extraFields[item.variable];
        }
        if (item.defaultValue) {
            return dotProp.get(extraFields, item.variable, dotProp.get(admission, item.defaultValue, ''));
        }
        if (item.defaultValueRaw) {
            return item.defaultValueRaw;
        }

        return '';
    }
    const setValue = event => {
        dispatchValue(event.target.value);
        event.preventDefault();
    }
    const dispatchValue = value => dispatch({type: actionTypes.MODAL_ADMISSION_TIME_SET_EXTRA_FIELD, variable: item.variable, value: value});

    const isDisabled = item.defaultValueTemplate || item.imp161 || item.disabled;

    useEffect(() => {
        if (item.defaultValue) { //  && !
            const _value = getValue();
            if (_value) {
                dispatchValue(_value)
            }
        }
    }, [selectedMessageTemplate]);

    useEffect(() => {
        // console.log('[ModalAdmissionTime_ExtraField] useEffect([])', item.variable, getValue());
        if (extraFields[item.variable] !== getValue()) {
            dispatchValue(getValue());
        }
    }, [extraFields]);

    return <div className={item.className}>
        {item.label}<br/>
        <input className="form-control" value={getValue()} onChange={setValue} disabled={isDisabled}/>
        {/*{selectedMessageTemplate.extraVariables} {admission.id}*/}
        {/*<input name="arrTime" className="form-control" onChange={handleArrTime} value={arrTime} />*/}
    </div>
}

export default ModalAdmissionTime_ExtraField;